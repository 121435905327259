<template>
  <div class="card">
    <div class="card-body pt-0 pb-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/info'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.info") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/weights'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.weight") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/vaccines'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/diseases'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.diseases") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/feeds'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.feed") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--
                <li class="nav-item">
                  <router-link
                    to="/animalsVaccine"
                    class="nav-link text-active-primary me-6"
                    active-class="active"
                  >
                    {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link text-active-primary me-6"
                    to="/feed"
                    active-class="active"
                  >
                    {{ $t("menuPages.animalInfo.tabMenu.feed") }}
                  </router-link>
                </li>
                              -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="isLoading"
    style="
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-35px"
        src="/media/loading_icon.gif"
        style="width: 40px"
      />
    </div>
  </div>

  <div
    v-else-if="animalVaccineList.length > 0"
    class="card mt-5"
    v-for="animalVaccine in animalVaccineList"
    :key="animalVaccine.id"
  >
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="row g-6 g-xl-9 ms-2 me-2" style="margin-top: 0.001em">
          <!--begin::-->
          <div class="card card-xl-stretch">
            <!--begin::Header-->
            <h1 class="fw-bolder mt-1 text-dark">{{ animalVaccine.name }}</h1>
            <span class="text-muted fw-bold fs-7">{{
              animalVaccine.description
            }}</span>

            <div
              v-if="animalVaccine.vaccineSchedule"
              class="border rounded mb-3 mt-4"
            >
              <div
                v-for="animalVaccineSchedule in animalVaccine.vaccineSchedule"
                :key="animalVaccineSchedule.id"
              >
                <div
                  class="card-header align-items-center rounded"
                  role="button"
                  data-bs-toggle="collapse"
                  v-bind:data-bs-target="
                    '#vaccine_schedule' + animalVaccineSchedule.id
                  "
                  v-if="animalVaccineSchedule.name"
                >
                  <h3 class="card-title align-items-start flex-column">
                    <span class="fw-bolder text-dark">{{
                      animalVaccineSchedule.name
                    }}</span>

                    <span class="text-muted mt-1 fw-bold fs-7">{{
                      animalVaccineSchedule.description
                    }}</span>
                  </h3>
                  <!--begin::Content-->

                  <!--end::Content-->
                </div>
                <div
                  v-if="animalVaccineSchedule.name"
                  v-bind:id="'vaccine_schedule' + animalVaccineSchedule.id"
                  class="collapse show"
                >
                  <!--begin::Card body-->

                  <div class="d-flex flex-wrap align-items-center">
                    <div class="card-body pt-5">
                      <template
                        v-for="(
                          vaccineScheduleItem, index
                        ) in animalVaccineSchedule.vaccineScheduleItems"
                        :key="index"
                      >
                        <!--begin::Item-->
                        <div
                          :class="{
                            'mb-7': vaccineScheduleItem.length - 1 !== index,
                          }"
                          class="d-flex align-items-sm-center"
                          v-for="(
                            vaccineSchedule, index
                          ) in vaccineScheduleItem"
                          :key="index"
                        >
                          <!--begin::Symbol-->
                          <div class="symbol symbol-45px me-5">
                            <img src="/media/animal/vaccine.png" />
                          </div>
                          <!--end::Symbol-->

                          <!--begin::Title-->
                          <div
                            class="
                              d-flex
                              flex-row-fluid flex-wrap
                              align-items-center
                            "
                          >
                            <div class="flex-grow-1 me-2">
                              <a
                                class="
                                  text-gray-800
                                  fw-bolder
                                  text-hover-primary
                                  fs-6
                                "
                                >{{ vaccineSchedule.attributes.name }}</a
                              >
                              <span class="text-muted fw-bold d-block pt-1">
                                {{
                                  vaccineSchedule.attributes.description
                                }}</span
                              >
                            </div>
                            <div
                              v-if="!vaccineSchedule.attributes.remainingDays"
                              class="min-w-50px pe-2"
                            >
                              <div class="min-w-125px py-3 px-4">
                                <div
                                  class="fs-7 text-gray-800 fw-bolder"
                                  style="text-align: end"
                                >
                                  {{
                                    new Date(
                                      vaccineSchedule.attributes.animalVaccineItem.data.attributes.completedDate
                                    ).toLocaleDateString("tr-TR")
                                  }}
                                </div>
                                <div class="fs-8 fw-bold text-gray-400">
                                  {{
                                    vaccineSchedule.attributes.animalVaccineItem
                                      .data.attributes.description
                                  }}
                                </div>
                              </div>
                            </div>
                            <span
                              v-if="
                                vaccineSchedule.attributes.remainingDays !==
                                null
                              "
                            >
                              <div>
                                <a
                                  class="
                                    btn btn-sm btn-light-primary
                                    me-3
                                    fs-8
                                    pt-2
                                    pb-2
                                    ps-3
                                    pe-3
                                  "
                                  disabled="true"
                                  data-bs-toggle="modal"
                                  data-bs-target="#schedule_vaccinate_modal"
                                  @click="
                                    selectScheduleVaccine(vaccineSchedule.id)
                                  "
                                >
                                  {{
                                    $t("menuPages.vaccination.addVaccination")
                                  }}
                                </a>
                                <span
                                  class="badge"
                                  :class="{
                                    'badge-light-primary':
                                      vaccineSchedule.attributes
                                        .remainingDays >= 0,
                                    'badge-light-danger':
                                      vaccineSchedule.attributes.remainingDays <
                                      0,
                                  }"
                                >
                                  {{
                                    vaccineSchedule.attributes.remainingDays !=
                                    null
                                      ? vaccineSchedule.attributes
                                          .remainingDays >= 0
                                        ? vaccineSchedule.attributes
                                            .remainingDays +
                                          $t("menuPages.vaccination.future")
                                        : Math.abs(
                                            vaccineSchedule.attributes
                                              .remainingDays
                                          ) + $t("menuPages.vaccination.past")
                                      : "Bilgi yok"
                                  }}
                                </span>
                              </div>
                            </span>

                            <span v-else>
                              <div>
                                <span class="badge badge-light-success">
                                  {{
                                    vaccineSchedule.attributes.animalVaccineItem
                                      .data.attributes.amount
                                  }}
                                  {{
                                    vaccineSchedule.attributes.animalVaccineItem
                                      .data.attributes.unitType
                                  }}
                                  aşı yapıldı.</span
                                >
                                <!--
                                <div class="fs-8 text-gray-800 fw-bolder" style="padding-top:5px">
                                 {{ new Date(vaccineSchedule.attributes.animalVaccineItem.data.attributes.completedDate).toLocaleDateString("tr-TR") }}
                                </div>
                                <div class="fw-bold text-gray-400">
                                  {{ vaccineSchedule.attributes.animalVaccineItem.data.attributes.description }}
                                </div>
                                -->
                              </div>
                            </span>
                          </div>
                          <!--end::Title-->
                        </div>
                        <!--end::Item-->
                      </template>
                    </div>
                  </div>

                  <!--end::Card body-->
                </div>
              </div>
            </div>
            <div
              class="border rounded mb-3 mt-4"
              v-if="animalVaccine.animalVaccineItems"
            >
              <div
                class="card-header align-items-center"
                role="button"
                data-bs-toggle="collapse"
                v-bind:data-bs-target="
                  '#vaccine_manual' + animalVaccine.animalVaccineItems[0].id
                "
              >
                <!-- : ="animalVaccine.id" -->
                <h3 class="card-title align-items-start flex-column">
                  <span class="fw-bolder text-dark">Harici Aşılamalar</span>
                </h3>
                <div class="card-toolbar">
                  <!--begin::Menu-->
                  <button
                    type="button"
                    class="
                      btn
                      btn-sm
                      btn-icon
                      btn-color-primary
                      btn-active-light-primary
                    "
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="top-end"
                  >
                    <span class="svg-icon svg-icon-2">
                      <inline-svg
                        src="media/icons/duotune/general/gen024.svg"
                      />
                    </span>
                  </button>
                  <!--end::Menu-->
                </div>
                <!--begin::Content-->

                <!--end::Content-->
              </div>
              <div>
                <div
                  v-bind:id="
                    'vaccine_manual' + animalVaccine.animalVaccineItems[0].id
                  "
                  class="collapse show"
                >
                  <!--begin::Card body-->

                  <div class="d-flex flex-wrap align-items-center">
                    <div class="card-body pt-5">
                      <template
                        v-for="(
                          animalVaccineItem, index
                        ) in animalVaccine.animalVaccineItems"
                        :key="animalVaccineItem.id"
                      >
                        <!--begin::Item-->
                        <div
                          :class="{
                            'mb-7':
                              animalVaccine.animalVaccineItems.length - 1 !==
                              index,
                          }"
                          class="d-flex align-items-sm-center"
                        >
                          <!--begin::Symbol-->
                          <div class="symbol symbol-45px me-5">
                            <img src="/media/animal/vaccine.png" />
                          </div>
                          <!--end::Symbol-->

                          <!--begin::Title-->
                          <div
                            class="
                              d-flex
                              flex-row-fluid flex-wrap
                              align-items-center
                            "
                          >
                            <div class="flex-grow-1 me-2">
                              <a
                                class="
                                  text-gray-800
                                  fw-bolder
                                  text-hover-primary
                                  fs-6
                                "
                                >{{ animalVaccineItem.attributes.amount }}
                                {{ animalVaccineItem.attributes.unitType }}</a
                              >

                              <span class="text-muted fw-bold d-block pt-1">
                                {{ animalVaccineItem.attributes.description }}
                              </span>
                            </div>

                            <span
                              class="
                                badge-light-success badge
                                fs-8
                                fw-bolder
                                my-2
                              "
                              >{{
                                new Date(
                                  animalVaccineItem.attributes.completedDate
                                ).toLocaleDateString("tr-TR")
                              }}</span
                            >
                          </div>
                          <!--end::Title-->
                        </div>
                        <!--end::Item-->
                      </template>
                    </div>
                  </div>

                  <!--end::Card body-->
                </div>
              </div>
            </div>
            <!--end::Header-->

            <!--begin::Body-->

            <!--end::Body-->
          </div>
          <!--end::-->
        </div>
      </div>
    </div>
  </div>
  <div v-else class="card-px text-center" style="margin-top: 30px">
    <div class="text-center px-5 pb-7">
      <img
        src="/media/animal/animal.jpg"
        class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
      />
    </div>
    <!--begin::Description-->
    <p class="text-gray-400 fs-5 fw-bold">
      <span>{{ $t("menuPages.vaccination.zeroCount") }}</span>
    </p>
    <!--end::Description-->
  </div>

  <Vaccinate></Vaccinate>
  <ScheduleVaccinate></ScheduleVaccinate>
  <AddWeight></AddWeight>
  <AddDisease></AddDisease>
  <Feed></Feed>
  <AnimalTransfer></AnimalTransfer>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import ScheduleVaccinate from "@/presentation/components/ScheduleVaccinate.vue";
import { COMPONENT_NAME, PAGE_TITLE_PATH } from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/domain/Constant";
import { AnimalVaccineListModel } from "@/domain/animal/animalVaccine/animalVaccineList/model/AnimalVaccineListModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: {
    Vaccinate,
    ScheduleVaccinate,
    AddWeight,
    AddDisease,
    Feed,
    AnimalTransfer,
  },
  setup() {
    const swalNotification = new SwalNotification();
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const animalVaccineList = ref<AnimalVaccineListModel[]>([]);

    const animalId = route.params["id"];
    const animalController = store.state.ControllersModule.animalController;

    watch(
      () => store.getters.newVaccine,
      () => {
        if (store.getters.newVaccine) getAnimalVaccines(1);
      }
    );

    const getAnimalVaccines = (animalId: number) => {
      isLoading.value = true;

      const animalVaccineListModel: AnimalVaccineListModel = {
        id: animalId,
      };

      animalController
        .animalVaccineList(animalVaccineListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(animalVaccineList.value, response.getValue());
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const selectScheduleVaccine = (id) => {
      store.dispatch("selectedScheduleVaccineId", id);
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      isLoading.value = true;
      getAnimalVaccines(Number(animalId));
    });

    return {
      PAGE_TITLE_PATH,
      animalId,
      animalVaccineList,
      selectScheduleVaccine,
      isLoading,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
