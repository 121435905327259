
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/presentation/helper/Dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import SwalNotification from "../plugins/SwalNotification";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { AnimalVaccineAddModel } from "@/domain/animal/animalVaccine/animalVaccineAdd/model/AnimalVaccineAddModel";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const swalNotification = new SwalNotification();

    const animalVaccinateModel = ref<AnimalVaccineAddModel>({
      amount: 0,
    });

    const vaccinationController =
      store.state.ControllersModule.vaccinationController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!vaccinationController) {
      throw new Error("VaccinationController is not available in Vuex store.");
    }

    //Create form validation object
    const form = Yup.object().shape({
      amount: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Amount"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description"),
      date: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Date"),
    });

    const submit = () => {
      loading.value = true;
      animalVaccinateModel.value.animal = Number(route.params["id"]);

      animalVaccinateModel.value.vaccineScheduleItem = Number(
        store.getters.selectedScheduleVaccineId
      );

      animalController
        .addAnimalVaccine(animalVaccinateModel.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            store.dispatch("newVaccine", store.getters.newVaccine + 1 );
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_ADD_ANIMAL_VACCINE),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      animalVaccinateModel,
      submit,
      loading,
      formRef,
      newTargetModalRef,
      form,
    };
  },
});
