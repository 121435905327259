
import { defineComponent, onMounted, ref, watch } from "vue";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import ScheduleVaccinate from "@/presentation/components/ScheduleVaccinate.vue";
import { COMPONENT_NAME, PAGE_TITLE_PATH } from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/domain/Constant";
import { AnimalVaccineListModel } from "@/domain/animal/animalVaccine/animalVaccineList/model/AnimalVaccineListModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: {
    Vaccinate,
    ScheduleVaccinate,
    AddWeight,
    AddDisease,
    Feed,
    AnimalTransfer,
  },
  setup() {
    const swalNotification = new SwalNotification();
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const animalVaccineList = ref<AnimalVaccineListModel[]>([]);

    const animalId = route.params["id"];
    const animalController = store.state.ControllersModule.animalController;

    watch(
      () => store.getters.newVaccine,
      () => {
        if (store.getters.newVaccine) getAnimalVaccines(1);
      }
    );

    const getAnimalVaccines = (animalId: number) => {
      isLoading.value = true;

      const animalVaccineListModel: AnimalVaccineListModel = {
        id: animalId,
      };

      animalController
        .animalVaccineList(animalVaccineListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(animalVaccineList.value, response.getValue());
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const selectScheduleVaccine = (id) => {
      store.dispatch("selectedScheduleVaccineId", id);
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      isLoading.value = true;
      getAnimalVaccines(Number(animalId));
    });

    return {
      PAGE_TITLE_PATH,
      animalId,
      animalVaccineList,
      selectScheduleVaccine,
      isLoading,
    };
  },
});
